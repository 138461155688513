import React from "react";
import {emptyGadget, Gadgets} from "./GadgetsCreator";
import GadgetDialog from "./GadgetDialog";
import {Grid} from "@material-ui/core";
import GadgetSmall from "./GadgetSmall";
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

export default function GadgetComponent(props){
    const [dialogGadget, setDialogGadget] = React.useState(emptyGadget);
    const [gadgetDialog, setOpenGadgetDialog] = React.useState(false);
    const {styles} = props;

    function handleCloseDialog ()  {
        setOpenGadgetDialog(false);
    }

    function openGadgetDialog (item) {
        setDialogGadget(item)
        setOpenGadgetDialog(true);

    }

    return(
        <div>
            <div style={styles.topPadding} id={"GadgetScrollPosition"}/>
                <Grid
                    style={styles.topPadding}
                    container
                    spacing={2}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item sm={12} >
                        <h1 id="gadgets" style={styles.title}>Gadgets</h1>
                    </Grid>

                    <Grid item sm={10} >

                    <div style={{textAlign:"center", color: "white", paddingBottom:"20px"}}>
                        Hier staan alle mooie gadgets die je had kunnen bestellen. Ze zijn ondertussen bezorgd en verspreid.
                    </div>
                    </Grid>
                    {/* <Grid container justify="center">
                        <div style={{paddingRight:"20px",justifyContent:"center"}}>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<ShoppingCartTwoToneIcon />}
                                style={{align:"center"}}
                                onClick={()=>window.open("https://docs.google.com/forms/d/e/1FAIpQLSflD95rBE4w-OFIoaCyj98xNKE9LTm3uuU-fQjuD_ZnroRpEg/viewform?usp=sf_link", "_blank") }
                            >
                                Google form
                            </Button>
                        </div>
                    </Grid> */}
                {

                    Gadgets.map((item) => {
                        return (
                            <Grid item sm={4} xs={6}>
                                <GadgetSmall
                                    item={item}
                                    click={ () =>  openGadgetDialog(item)}
                                />
                            </Grid> )
                    })
                }
            </Grid>
            <div  style={{ textAlign:"center", color: "white", ...styles.topPadding}}>
            </div>
            <GadgetDialog open={gadgetDialog} close={handleCloseDialog} item = {dialogGadget}/>
        </div>
    )
}