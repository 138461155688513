import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {DialogContentText} from "@material-ui/core";
import Carousel, {Dots} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import DialogContent from "@material-ui/core/DialogContent";


export default function ActivityDialog(props) {
    const { open, close, activity} = props;
    const[value, setValue] = React.useState(0);
    //item has name text and images (arraylist maybe

    function onChange(value) {
        setValue( value )
    };

    return (
        <Dialog open={open} onClose={close} maxWidth={"sm"}  >
            <DialogTitle style = {{textAlign: "center"}} >{activity.name}</DialogTitle>
            <DialogContent>
                <Carousel
                    value={value}
                    onChange={(value) => onChange(value)}
                    style={{height: "80%", width: "80%"}}
                >
                    {
                        activity.images.map((image) => {
                            return (
                                    <img
                                        alt={"Not found"}
                                        src={image}
                                        style={{maxHeight: "60vh"}}
                                    />
                            )
                        })
                    }
                </Carousel>
                <Dots style={{backgroundColor: "white"}}value={value} onChange={onChange} number={ activity.images.length} />
            </DialogContent>
            <DialogContentText style = {{padding: "5px 20px" ,textAlign: "justify"}}>
                {activity.bigText}
            </DialogContentText>
        </Dialog>
    );
}


