import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import {CardHeader} from "@material-ui/core";


const useStyles = makeStyles({
        root: {
        },
        media: {
            height: 140,
        },
    });

export default function GadgetSmall(props){
    const { clicked, item , click} = props;
        const classes = useStyles();

        return (
            <Card onClick={click} className={classes.root}>
                <CardActionArea onClick={clicked}>
                    <CardHeader style={{color:"black", textAlign:"center"}} title={item.name}/>
                    <CardMedia
                        title="Click me!"
                    >
                        <img alt="Not found" src={item.image} style={{width: "100%"}} />
                    </CardMedia>
                    {/*<CardContent>*/}
                    {/*    <Typography gutterBottom variant="h5" component="h2">*/}
                    {/*        */}
                    {/*    </Typography>*/}
                    {/*    /!*<Typography variant="body2" color="textSecondary" component="p">*!/*/}
                    {/*    /!*    {item.text}*!/*/}
                    {/*    /!*</Typography>*!/*/}
                    {/*</CardContent>*/}
                </CardActionArea>
                {/*<CardActions>*/}
                {/*    <Button size="small" color="primary">*/}
                {/*        Share*/}
                {/*    </Button>*/}
                {/*    <Button size="small" color="primary">*/}
                {/*        Learn More*/}
                {/*    </Button>*/}
                {/*</CardActions>*/}
            </Card>
        );
    }