import Das from "../../media/Das.jpeg";
import Pull from "../../media/Pull.png";
import Spatel from "../../media/spatel.png";
import Speciaal from "../../media/BierglasBreughel.png";
import Kaarten from "../../media/kaarten.png";
import Tegel from "../../media/tegel.png";
import Mondkapje from "../../media/Mondkapje.jpg";
import Dobbelsteen from "../../media/dobbelsteen.png";

export const Gadgets = [
    {
        name:"Lustrum Das",
        text:
        "Een lustrum is natuurlijk niet compleet zonder dassen, hiervoor zal een selectie gemaakt worden van verschillende designs." +
        " Het zal natuurlijk het lustrum logo een mooie plaats op de borst geven en een mooi aandenken zijn." ,
        image: Das },
    {
        name:"Pull",
        text:
            "Zin in glas dat uitstraalt wat voor grote speler je bent? " +
            "Wil je goude rakkers uit een waardig glas drinken? " +
            "Het zal natuurlijk een fatsoenlijke halve liter aan vloeibaar brood kunnen houden wat natuurlijk een perfecte hoeveelheid is om vanuit huis te cantussen of lekker met de makkers te zuipen." +
            "",
        image: Pull
    },
    {
        name:"Tegeltjes",
        text:
            "Een echt Nederlands huishouden kan natuurlijk niet zonder tegeltjes met echte wijsheden. " +
            "Een mooie tegel met uitstekende teksten naar eigen keuze. " +
            "Hierbij een paar mooie voorbeelden: " +
            '"Home drinking is killing to kroegbaas."' +
            '"Één bier is geen bier."' +
            '"Als de tappers achter de bar vandaan zijn. Dansen de leden op de bar."' +
            '"Bier? Eentje kan geen kwaad..."' +
            "",
        image: Tegel
    },
    {
        name:"Spatels",
        text:
            "Altijd al gedroomd van je eigen thuisbar? Of zoek je een mooie toevoeging voor je huisfeest set-up?" +
            " Regel nu een lustrum spatel voor het betere afschuim werk.",
        image: Spatel
    },
    {
        name:"Speciaal glazen",
        text:
            "Kies je voor het duurdere broertje van je geliefde pils? Doe dit dan in een bijbehorend speciaalbier glas. " +
            " " +
            "",
        image: Speciaal
    },
    {
        name:"Kaarten",
        text:
            "Bij een gezellige gelegenheid kan een stok kaarten niet ontbreken natuurlijk.",
        image: Kaarten
    },
    {
        name:"Mondkapje",
        text: "Help een handje mee in de huidige pandemie door een mondkapje te dragen, nu kan het ook in stijl met een zwart mondkapje met het Keizerlustrum- en Demos logo er op.",
        image: Mondkapje
    },
    {
        name:"Dobbelstenen",
        text: "Wil je een avondje gezellig borrelen nog leuker maken, of gewoon een keer niet saaie dobbelstenen hebben? Dan heb je geluk met deze limited-edition keizerlustrumdobbelstenen.",
        image: Dobbelsteen
    }
];

export const emptyGadget = {name:"dur", text: "dur" , image: Das };