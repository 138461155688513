import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import {Intro,} from "../components/Intro";
import ActivityComponent from "../components/activity/ActivityComponent";
import {Activities} from "../components/activity/ActivityCreator";
import GadgetComponent from "../components/gadget/GadgetComponent";
import Alert from "@material-ui/lab/Alert";

const useStyles = {
    containers:{

    },
    topPadding:{
        paddingTop:"30px",
    },
    title:{
        color:"#DB271D",
        textShadow:"-1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF",
        textAlign:"center",
    }
};

export default function LustrumPagina(){
    const [openSnackbar, setOpenSnackbar] = React.useState(true);
    const styles = useStyles;

    function handleClickOpenSnackbar() {
        setOpenSnackbar(true);
    }



    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }


    return (
        <div style={{alignContent:"center", padding:"5%"}}>
            <div style={{ height:"40vw", maxHeight: "80vh",width:"90%"}} >
                <iframe
                    title={"Video"}
                    style={{
                        position: "absolute",
                        left: "10%",
                        top: "70px",
                        width: "80vw",
                        height: "40vw",
                        maxHeight:"80vh"
                    }}
                    src={`https://drive.google.com/file/d/1IZOua1hnkZLObQrUjz6ZRqu0JXVc77jI/preview`}
                    frameBorder="0"
                    allowFullScreen
                    
                />
            </div>

            <Intro title={styles.title} />

            <ActivityComponent Activities={Activities} styles={styles} />

            <GadgetComponent styles={styles}/>
            <Snackbar  autoHideDuration={6000} open={openSnackbar} onClose={handleCloseSnackbar} >
                <Alert severity="info">
                    Klik op de activiteiten en gadgets
                </Alert>
            </Snackbar>

        </div>
    )
}
