import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {DialogContentText} from "@material-ui/core";


export default function GadgetDialog(props) {
    const { open, close, item} = props;



    return (
        <Dialog open={open} onClose={close} style = {{ width:"sm", textAlign: "center"}}  >
            <DialogTitle style = {{textAlign: "center"}} >{item.name}</DialogTitle>
            {/*<DialogTitle id="simple-dialog-title">{item.name}</DialogTitle>*/}
            <img alt="Not found" src={item.image} style={{width:"60%", padding: "0% 20%" }}/>
            <div style={{padding: "5px 10px"}}>
            <DialogContentText style = {{textAlign: "justify"}}>
                {item.text}
            </DialogContentText>
            </div>
        </Dialog>
    );
}


