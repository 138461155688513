import React from "react";
import Whatshot from '@material-ui/icons/Whatshot';
import MusicNote from '@material-ui/icons/MusicNote';
import Movie from '@material-ui/icons/Movie';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

export default function ActivityTimeline(props) {
    const {clickActivity,Activities} = props;

    function activityIcon(activity) {
        switch(activity.name) {
            case 'Onthulling':
                return <Movie style={{color:"#DB271D", cursor: "pointer"}} onClick={()=>{clickActivity(activity)}} />;
            case 'Bandtus':
                return <MusicNote style={{color:"#DB271D", cursor: "pointer"}} onClick={()=>{clickActivity(activity)}} />;
            case 'Sinterkerst Roast':
                return <Whatshot style={{color:"#DB271D", cursor: "pointer"}} onClick={()=>{clickActivity(activity)}} />;
            case 'Online borrelactiviteit':
                return <DesktopWindowsIcon style={{color:"#DB271D", cursor: "pointer"}} onClick={()=>{clickActivity(activity)}} />;
        }
    }

    return(
        <Timeline align="alternate" >
            {
                Activities.map((Activity) =>{
                return(
                    <TimelineItem >
                        <TimelineOppositeContent>
                            <Typography variant="h6" style={{color:"white"}}>
                                {Activity.date}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                {activityIcon(Activity)}
                            </TimelineDot>
                            <TimelineConnector/>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper style={{backgroundColor: "transparent", cursor: "pointer"}} onClick={()=>{clickActivity(Activity)}} elevation={0} >
                                <Typography style={{color:"#DB271D"}} variant="h6" component="h1" >
                                    {Activity.name}
                                </Typography>
                                <Typography style={{color:"#DB271D"}}>{Activity.text}</Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    )
                })
            }
        </Timeline>


    )
}