import React from "react";
import {Grid} from "@material-ui/core";
import logo from "../media/logo.png";


export function Intro(props){
    const title = props.title;
    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            justify="center"
        >
            <Grid item xs={12} sm={4}>
                    <img alt="Not found" src={logo} style={{display:"block", margin: "auto", width: '70%' }}/>
                    <div id={"ActiviteitenScrollPosition"}/>
            </Grid>
            <Grid item xs={12} sm={6}>
            <div style={{
                    width: "80%",
                    textAlign: "justify",
                    color:"white",
                    margin: "auto"
                }}>
                <h2 style={title}>1e Keizerlustrum der E.S.V. Demos "Vuurdoop" </h2>
                
                    Vuurdoop, een herboren soos, gedoopt als een pas geboren baby in een zee van vlammen, als een phoenix gestorven en uit zijn eigen as herrezen.
                Nero waakt al 5 jaar lang over onze sociëteit en dat mag grootsuit gevierd worden!
                </div>
            </Grid>
        </Grid>
    )
}