import React from 'react';
import '../styles/Homepage.css';
import LustrumPagina from "./LustrumPagina";
import Header from "../components/Header";
import Toolbar from "@material-ui/core/Toolbar";
import Background from "../media/achtergrond.png";
import {ContactCard} from "../components/ContactCard";
// import { ThemeProvider } from '@material-ui/core/styles';

// const theme = {
//     background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
// };
function App() {
  return (
      <div
          style={{
              backgroundImage:"url("+Background+")",
              backgroundRepeat:"repeat-y",
              backgroundSize: "100vw"
          }}
      >
          <div>
              <Header/>
              <Toolbar/>
              <LustrumPagina/>
              <ContactCard/>
              <div style={{height:"30px",padding: "5px 5px", backgroundColor:"#451F1C"}}>
                  E.S.V. Demos 2020
              </div>
          </div>
      </div>
  )
}
export default App;
