import Band1        from "../../media/activiteiten/bandtus/band1.jpeg";
import Band2        from "../../media/activiteiten/bandtus/band2.jpeg";
import Band3        from "../../media/activiteiten/bandtus/band3.jpeg";
import Band4        from "../../media/activiteiten/bandtus/band4.jpeg";
import Praesidium1  from "../../media/activiteiten/bandtus/praesidium1.jpeg";
import Praesidium2  from "../../media/activiteiten/bandtus/praesidium2.jpeg";
import Binnen1        from "../../media/activiteiten/onthulling/binnen1.jpeg";
import Binnen2        from "../../media/activiteiten/onthulling/binnen2.jpeg";
import Buiten1        from "../../media/activiteiten/onthulling/buiten1.jpeg";
import Buiten2        from "../../media/activiteiten/onthulling/buiten2.jpeg";
import Poster        from "../../media/activiteiten/onthulling/poster.jpeg";
import Promo         from "../../media/lustrumweek.jpeg";
import Roast         from "../../media/roast.jpeg";
import React from "react";


export let Activities = [
    {
        name:"Onthulling",
        date:"7 oktober",
        text:
            "De onthulling van het lustrumthema!" ,
        bigText:
            "Op de soos en online op Zoom werd om 20:00 het lustrumthema 'Vuurdoop' onthuld doormiddel van een speech van de voorzitter en een onthullingsfilmpje.  " + 
            "Ook werd op de soos de prachige banier onthuld, waarna het eindigde in een geweldige borrel.",
        images:[
            Binnen1,
            Binnen2,
            Buiten1,
            Buiten2,
            Poster
        ]
    },
    {
        name:
            "Bandtus",
        text:
            "Een gezellige avond vanuit huis zingen en pilzen",
        date:
            "16 oktober",
        bigText:
            "Ook corona kan Demos niet weerhouden van een gezellige cantus. " +
            "Hierbij werden met gepaste afstand op de sociëteit de instrucies gegeven door het praesidium met ondersteuning van de Demos band. " +
            "Het was een zeer gezellige avond voor de leden thuis, die hebben kunnen genieten van de band en de creatieve straffen van de strafmeester",
        images:
            [
                Band1,
                Band2,
                Band3,
                Praesidium2
            ]
    },
    {
        name:
            "Sinterkerst Roast",
        text:
            "Geen enkel lid is veilig",
        date:
            "14 december",
        bigText:
            "Sinterklaas heeft een appeltje te schillen met sommige leden van onze vereniging.",
        images:
            [
                Roast
            ]
    },
    {
        name: 
            "Online borrelactiviteit",
        text:
            "Coronaproof borrelen",
        date:
            "3 maart",
        bigText:
            "Er is goed gezopen op het platform Wonder.me",
        images:
            []
    },
    {
        name: 
            "Verjaardagsstream van De Keizer",
        text:
            "Kick-off van de lustrumweek!",
        date:
            "8 mei",
        bigText:
            "De terrassen mogen weer open, de avklo gaat eraf. Het is dus tijd voor een feestje en daarom organiseert de KLC een week vol activiteiten om het lustrum te vieren!",
        images:
            [Promo]
    },
    {
        name: 
            "Online brasspel",
        text:
            "Verover de drankflessen en red de soos!",
        date:
            "10 mei",
        bigText:
            "Op een warme zomerdag komen de tappers de soos binnen en treffen hier een ravage aan. Iemand heeft de deur open laten staan en er ligt een brief dat de andere Eindhovense verengingen onze drank in bewaring hebben genomen. Maar op zijn heerlijke zuipdag als deze kan de soos niet zonder zijn drank. Gelukkig heeft het bestuur voor vertrek de bestuurskamer wel dicht gehouden en hebben en formuleren ze een plan daar. Het bestuur looft een prijs uit voor de leden dat met de meeste drank terugkomt om de borrelavond te redden.",
        images:
            []
    },
    {
        name: 
            "Centurion",
        text:
            "100 minuten, 100 shotjes, wie overleeft het?",
        date:
            "12 mei",
        bigText:
            "",
        images:
            []
    },
    {
        name: 
            "Uitbrakdonderdag",
        text:
            "Lekker uitbrakken op de soos of online",
        date:
            "13 mei",
        bigText:
            "",
        images:
            []
    }, 
    {
        name: 
            "Demoskennisquiz",
        text:
            "Test je kennis over onze mooie vereniging!",
        date:
            "14 maart",
        bigText:
            "",
        images:
            []
    }
]

export const emptyActivity = {name:"dur", text: "dur" , date:"dur", bigText: "dur", images: [] };