import React from 'react';
import { AppBar, Toolbar, Button, withStyles } from '@material-ui/core';
import leeuwzw from "../media/leeuwzw.png";

export default function Header() {

  const StyledToolbar = withStyles({
    root: {
      background: '#E1341E'
      
    }
  })(Toolbar);

  const StyledButton = withStyles({
    root: {
      padding: '12px',
        fontWeight:"bold",
        fontFamily:"Verdana",
    }
  })(Button);

  return (
      <AppBar position="fixed">
          <StyledToolbar>
              <img alt="Not found" onClick={() =>{window.scrollTo(0, 0)}} src={leeuwzw}  style={{height: "50px", marginRight:"12px"}}/>
              <StyledButton href="#ActiviteitenScrollPosition">Activiteiten</StyledButton>
              <StyledButton href="#GadgetScrollPosition">Gadgets</StyledButton>

          </StyledToolbar>
      </AppBar>
  );
}

