import React from "react";
import ActivityTimeline from "./ActivityTimeline";
import ActivityDialog from "./ActivityDialog";
import {emptyActivity} from "./ActivityCreator";

export default function ActivityComponent(props){
    const {styles, Activities} = props;
    const [activityDialog, setOpenActivityDialog] = React.useState(false);
    const [activity, setActivity] = React.useState(emptyActivity);


    function openActivityDialog (Activity) {
        setActivity(Activity);
        setOpenActivityDialog(true);

    }

    function closeActivityDialog(){
        setOpenActivityDialog(false);
    }

    return(
        <div>
            <div style={styles.topPadding}  >
                <h1 style={styles.title}>
                    Activiteiten
                </h1>
            </div>
            <ActivityTimeline Activities={Activities} clickActivity={(Activity)=>{openActivityDialog(Activity)}} />
            <ActivityDialog open={activityDialog} close={closeActivityDialog} activity={activity}/>
            <div style={{textAlign:"center", color:"white"}}>
                Je kan op de activiteiten klikken voor foto's en meer informatie.
            </div>
        </div>
    )
}