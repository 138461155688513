import React from "react";
import {Grid} from "@material-ui/core";
import logo_demos from "../media/logo_demos.svg";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    main: {
        backgroundColor:"#000000",
        paddingTop:"50px",
        paddingBottom:"30px",
        color: "white",
    },
    img:{
        maxHeight:"calc(min(450px,30vh))",
        padding: "0px 30px",
        width: "40vw",
    }
});

export function ContactCard() {
    const classes = useStyles();
    return(
        <div>

            <div
                className={classes.main}
            >
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item sm={6} xs={12}>
                        <img alt="Not found" className={classes.img} src={logo_demos}/>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <div style={{padding:"0px 10px"}}>
                            <h3>
                                Adres
                            </h3>
                            Sociëteit de Keizer<br/>
                            Keizersgracht 17<br/>
                            5611 GC  Eindhoven
                        </div>
                    </Grid>
                    <Grid item sm={3} xs={6}>

                        <h3>
                            Contact
                        </h3>
                        <a
                            style= {{color:"#FFFFFF", textDecorationLine: "none"}}
                            href = "mailto:keizerlustrum@demos.nl?subject = Keizerlustrum&body = Beste KLC,"
                        >
                            keizerlustrum@demos.nl
                        </a>
                        <br/>
                        <br/>

                    </Grid>
                </Grid>
            </div>
        </div>
    )
}